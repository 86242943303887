import React from "react"

import "./Footer.scss"

import PartnerPL from "../images/partner_pl.png"
import PartnerESZL from "../images/partner_eszl.png"
import Playstore from "../images/Playstore.svg"
import Appstore from "../images/Appstore.svg"
import facebook from "../images/facebook.svg"
import instagram from "../images/instagram.svg"
import tiktok from "../images/tiktok.svg"
import youtube from "../images/youtube.svg"

export const Footer = () => (
  <div className="Footer">
    <div className="layout">
      <div className="Footer__row">
        <div className="Footer__column">
          <div className="Footer__contact-info">
            <p>
              <strong>Contact</strong>
            </p>
            <p>New Dance TV</p>
            <p>Kloosterweg 1</p>
            <p>6412CN Heerlen</p>
            <p>The Netherlands</p>
            <p>info@newdancetv.com</p>
            <div>
            <a href="/legal/eula"><p>Mobile App End-User License Agreement</p></a>
            <a href="/legal/privacy"><p>Privacy Policy</p></a>
            </div>
          </div>
        </div>
        <div className="Footer__row">
        <div className="Footer__download">
        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/nl/app/new-dance-tv/id1557527272?l=en"><img src={Appstore} alt="App Store" /></a>
        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.newdancetv.mobile&hl=nl&gl=US"><img src={Playstore} alt="Play Store" /></a>
        </div>
      </div>
      </div>
      <div className="Footer__partners">
        <img src={PartnerPL} alt="Logo ESZL" />
        <img src={PartnerESZL} alt="Logo provincie Limburg" />
      </div>
      <div className="Footer__socials">
        <a target="_blank" href="https://www.facebook.com/NewDanceTV/" rel="noreferrer"><img src={facebook} alt="facebook" /></a>
        <a target="_blank" href="https://www.instagram.com/newdancetv/" rel="noreferrer"><img src={instagram} alt="instagram" /></a>
        <a target="_blank" href="https://www.tiktok.com/@newdancetv" rel="noreferrer"><img src={tiktok} alt="tiktok" /></a>
        <a target="_blank" href="https://www.youtube.com/c/NewDanceTV" rel="noreferrer"><img src={youtube} alt="youtube" /></a>
      </div>
      <div className="Footer__copyright">
        <p>
          Copyright &copy;{new Date().getFullYear()} - Shots Fired B.V. - KvK
          76685195
        </p>
      </div>
    </div>
  </div>
)
