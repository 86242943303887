import React from "react"
import preview from "../images/app-preview.png"

import "./Modal.scss"
import "./AnimatedMobile.scss"

export const AnimatedMobile = () => {
  return (
    <div className="layout">
      <div className="AnimatedMobile">
        <div className="AnimatedMobile__graphic">
          <div className="AnimatedMobile__graphic__inner">
            <div
              className="AnimatedMobile__graphic__inner__base"
            >
              <img src={preview} alt="" height="50%" width="50%"/>
            </div>
          </div>
        </div>
        <div className="AnimatedMobile__content">
          <div className="text-container--small">
            <h1>A mobile app made for you</h1>
            <p>
            All our videos are hand-picked by experts with decades of experience to ensure you get the highest quality content.
            </p>
            <main>
            </main>
          </div>
        </div>
      </div>
    </div>
  )
}
