import React from 'react'
import * as Logo from '../assets/logo.svg'

import './Header.scss'

export const Header = () => (
    <div className="Header">
        <div className="layout Header__content">
            <div className="Header__content__logo">
                <Logo/>
            </div>
        </div>
    </div>
)
