import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import * as IllustrationSchoolBase from '../assets/illustration-school-base.svg'
import * as IllustrationSchoolAnimationLayer from '../assets/illustration-school-layers.svg'
import * as Bullet from '../assets/bullet.svg'
import media from "../images/media.png"
import social from "../images/social.png"
import dedicated from "../images/dedicated-app.png"

import './AnimatedSchool.scss'

export const AnimatedSchool = () => {   
    const [currentActivePanelIndex, setCurrentActivePanelIndex] = useState(0)

    return (
        <div className="layout">
            <div className="AnimatedSchool">
                <div className="AnimatedSchool__content">
                    <div className="text-container--small">
                        <h1>What we offer</h1>
                        <div
                            className={classnames("AnimatedSchool__content__option", {
                                "AnimatedSchool__content__option--active": currentActivePanelIndex === 0
                            })}
                            onClick={() => setCurrentActivePanelIndex(0)}
                        >
                            <div className="AnimatedSchool__content__option__bullet">
                                <Bullet/>
                            </div>
                            <div className="AnimatedSchool__content__option__content">
                                <p><strong>Media Production</strong></p>
                                <p>High quality productions ranging from livestreams to short documentaries</p>
                            </div>
                        </div>
                        <div
                            className={classnames("AnimatedSchool__content__option", {
                                "AnimatedSchool__content__option--active": currentActivePanelIndex === 1
                            })}
                            onClick={() => setCurrentActivePanelIndex(1)}
                        >
                            <div className="AnimatedSchool__content__option__bullet">
                                <Bullet/>
                            </div>
                            <div className="AnimatedSchool__content__option__content">
                                <p><strong>Social Media</strong></p>
                                <p>A vibrant community to share dance content that reaches millions. 🚀</p>
                            </div>
                        </div>
                        <div
                            className={classnames("AnimatedSchool__content__option", {
                                "AnimatedSchool__content__option--active": currentActivePanelIndex === 2
                            })}
                            onClick={() => setCurrentActivePanelIndex(2)}
                        >
                            <div className="AnimatedSchool__content__option__bullet">
                                <Bullet/>
                            </div>
                            <div className="AnimatedSchool__content__option__content">
                                <p><strong>Dedicated App</strong></p>
                                <p>Where dancers and festivals share, connect and earn revenue on their content ⚡</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AnimatedSchool__graphic">
                    {currentActivePanelIndex === 0 && <img src={media} alt="Media Production"/>}
                    {currentActivePanelIndex === 1 && <img src={social} alt="Social Media"/>}
                    {currentActivePanelIndex === 2 && <img src={dedicated} alt="Dedicated App"/>}
                </div>
            </div>
        </div>
    )
}